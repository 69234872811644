import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { useSelector, connect, useDispatch } from "react-redux";
import { fetchBusinesses, fetchLocations, fetchPricing } from "../state";
import { GlobalStyle } from "../style/globalStyles";
import { Layout } from "../components/Layout";
import { RidesSection as PricingSection } from "../style/rides";
import { PricingTable } from "../components/PricingTable";
import { BallBeat } from "react-pure-loaders";
import { PaymentForm as PricingForm } from "../style";
function PricingContainer({
  businessesData,
  fetchBusinesses,
  productpricing,
  locationData,
  fetchLocations,
}) {
  const dispatch = useDispatch();

  const loginData = useSelector((state) => state.auth.user);
  // console.log(loginData);
  const loginBool = Boolean(loginData.portalUserId);
  var businessesOptions = businessesData
    .filter((business) => {
      return (
        business.isActive === true &&
        business.isShowForRetail === true &&
        business.isApi === false
      );
    })
    .map((type) => (
      <option value={type.id} key={type.id} label={type.businessName}>
        {" "}
        {type.businessName}{" "}
      </option>
    ));
  var locationOptions = locationData.map((type) => (
    <option value={type.id} key={type.id} label={type.name}>
      {" "}
      {type.name}{" "}
    </option>
  ));
  useEffect(() => {
    if (!loginBool) {
      navigate("/login");
    }
    if (businessesData.length === 0) {
      fetchBusinesses();
    }
    if (locationData.length === 0) {
      fetchLocations();
    }
  }, [fetchBusinesses, fetchLocations]);
  // console.log(state, ridesFlag);
  const createOptions = (inputArray) => {
    return inputArray.map((type) => (
      <option value={Number(type.value)} key={type.value}>
        {" "}
        {type.label}{" "}
      </option>
    ));
  };
  const chaufferDrivenOptions = [
    { label: "One Way", value: 100010011 },
    { label: "Round Trip", value: 100010001 },
  ];
  const airportTransferOptions = [
    { label: "Pick Up", value: 100010014 },
    { label: "Drop", value: 100010015 },
  ];
  const hourlyRentalsOptions = [
    { label: "4 Hrs | 40 Kms", value: 100010006 },
    { label: "8 Hrs | 80 Kms", value: 10001007 },
  ];
  const [productType, setProductType] = useState(1);
  const [inputPricingData, setInputPricingData] = useState({
    accountId: null,
    locationId: null,
    // measureLookupId: 100010011,
  });
  return (
    <>
      <GlobalStyle />
      <Layout>
        <PricingSection>
          <h2>
            Product Pricing
            <Link
              style={{
                // backgroundColor: "rebeccapurple",
                backgroundColor: "steelblue",
                cursor: "pointer",
                width: "fit-content",
                cursor: "pointer",
                fontSize: ".6rem",
                padding: ".7rem",
                height: "fit-content",
                fontWeight: "normal",
                wordSpacing: "2px",
                marginRight: "3rem",
                border: "none",
                float: "right",
                borderRadius: "1rem",
                color: "white",
              }}
              to="/addorupdatepricing"
            >
              + Add Pricing
            </Link>
          </h2>
          {/* <br /> */}

          <br />
          {/* <VendorPayoutsForm businessesData={businessesData} /> */}
          <PricingForm>
            <div
              style={{
                width: "80%",
                display: "flex",
                margin: "auto 0",
                // alignText: "center",
              }}
            >
              <label>
                Business Name
                <select
                  style={{
                    marginTop: ".7rem",
                  }}
                  onChange={(e) => {
                    setInputPricingData({
                      ...inputPricingData,
                      accountId: Number(e.target.value),
                    });
                  }}
                >
                  <option>Select a vendor</option>
                  {businessesOptions}
                </select>
              </label>
              <label>
                Location
                <select
                  style={{
                    marginTop: ".7rem",
                  }}
                  onChange={(e) => {
                    setInputPricingData({
                      ...inputPricingData,
                      locationId: Number(e.target.value),
                    });
                  }}
                >
                  <option>Select a Location</option>
                  {locationOptions}
                </select>
              </label>

              <label>
                Product Type
                <select
                  style={{
                    marginTop: ".7rem",
                  }}
                  onChange={(e) => {
                    Number(e.target.value) === 2 &&
                      setInputPricingData({
                        ...inputPricingData,
                        categoryId: 100090053,
                      });
                    setProductType(Number(e.target.value));
                  }}
                >
                  <option value={1}>Chauffer Driven</option>
                  <option value={2}>Self Drive</option>
                  <option value={3}>Airport Transfer</option>
                  <option value={4}>Hourly Rentals</option>
                </select>
              </label>
              {productType != 2 && (
                <label>
                  Sub Category
                  <select
                    style={{
                      marginTop: ".7rem",
                    }}
                    onChange={(e) => {
                      setInputPricingData({
                        ...inputPricingData,
                        measureLookupId: Number(e.target.value),
                      });
                    }}
                  >
                    <option>Select a sub category</option>
                    {productType === 1 && createOptions(chaufferDrivenOptions)}

                    {productType === 2 && <option>Nothing to select</option>}
                    {productType === 3 && createOptions(airportTransferOptions)}
                    {productType === 4 && createOptions(hourlyRentalsOptions)}
                  </select>
                </label>
              )}

              <label>
                <button
                  style={{
                    width: "max-content",
                    height: "fit-content",
                    backgroundColor: "seagreen",
                    color: "white",
                    border: "none",
                    padding: ".5rem",
                    margin: "1.5rem 1rem auto 1rem",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    if (
                      inputPricingData.accountId !== null &&
                      inputPricingData.locationId !== null &&
                      inputPricingData.measureLookupId !== null
                    ) {
                      e.preventDefault();
                      // console.log(inputPricingData);
                      dispatch(fetchPricing(inputPricingData));
                    } else {
                      e.preventDefault();

                      alert("Please select all the required fields!");
                    }
                  }}
                >
                  Get Product Pricing
                </button>
              </label>
            </div>
          </PricingForm>
          <br />
          {productpricing.loadingpricing ? (
            <h3>
              Loading Product Pricing List{" "}
              <span>
                <BallBeat
                  color={"#123abc"}
                  loading={productpricing.loadingpricing}
                />
              </span>{" "}
            </h3>
          ) : productpricing.errorpricing ? (
            <>
              <h3>ERROR ⚠️ FETCHING Product Pricing</h3>
              <br />
              <p style={{ color: "grey" }}>{productpricing.errorpricing}</p>
              <br />
              <br />
            </>
          ) : (
            <>
              {productpricing.pricing.length > 0 ? (
                <>
                  <PricingTable
                    pricing={productpricing.pricing}
                    type={productType}
                  />
                </>
              ) : (
                "No Product Pricing found ⚠️"
              )}
            </>
          )}
        </PricingSection>
      </Layout>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    businessesData: state.vendors.businesses,
    locationData: state.vendors.locations,
    productpricing: state.agencies,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchBusinesses: () => dispatch(fetchBusinesses()),
    fetchLocations: () => dispatch(fetchLocations()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PricingContainer);
