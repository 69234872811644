import React from "react";
import { Table } from "./Table";
import { TableStyles } from "../style/globalStyles";

export const PricingTable = ({ pricing, type }) => {
  const columns = [
    {
      Header: "Category",
      accessor: "categoryDesc",
    },
    {
      Header: "Sub Category",
      accessor: "subCategoryDesc",
    },
    {
      Header: "Driver Charges",
      accessor: "driverCharges",
      show: type != 2,
    },
    {
      Header: "Night Charges",
      accessor: "nightCharges",
      show: type != 2,
    },
    {
      Header: "Actual Cost Per Km",
      accessor: "actualCostPerKm",
    },
    {
      Header: "Actual Cost Per Hr",
      accessor: "actualCostPerHr",
    },
    {
      Header: "Extra Cost Per Km",
      accessor: "extraCostPerKm",
    },
    {
      Header: "Extra Cost Per Hr",
      accessor: "extraCostPerHr",
    },
  ];

  var data = React.useMemo(() => pricing, []);

  var tableRender = (
    <Table
      getTdProps={(state, rowInfo, column, instance) => {
        return {
          // onClick: (e) => console.log("row clicked", rowInfo),
        };
      }}
      // Pass the page url here to navigate to onClick
      extras={true}
      path={"/addorupdatepricing"}
      columns={columns}
      data={data}
    />
  );

  return <TableStyles>{pricing ? tableRender : " "}</TableStyles>;
};
